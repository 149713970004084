import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  TitleContent,
  WorkedWith,
} from "../components/parts"

const NeonLaksa = () => (
  <Layout title="NeonLaksa">
    <Title
      heading="NeonLaksa"
      sub="A colouring book for the nation of foodies"
    />
    <Overview
      project="neonlaksa"
      text={[
        "Singaporeans' love for food is authentic. Hence a coloring book with all the local delicacies of Singapore and their ingredients. I drew the sketches in the book.",
      ]}
    />
    <TitleContent title="The book">
      <div className="text-center">
        <img src="/images/neonlaksa/cover.png" alt="cover" />
      </div>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img
            src="/images/neonlaksa/1.png"
            alt="cover"
            style={{ marginBottom: 30 }}
          />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img
            src="/images/neonlaksa/2.png"
            alt="cover"
            style={{ marginBottom: 30 }}
          />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img
            src="/images/neonlaksa/3.png"
            alt="cover"
            style={{ marginBottom: 30 }}
          />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img
            src="/images/neonlaksa/4.png"
            alt="cover"
            style={{ marginBottom: 30 }}
          />
        </Col>
      </Row>
    </TitleContent>
    <Toolset project="neonlaksa" />
    <Involvement project="neonlaksa" />
    <Visit project="neonlaksa" />
    <WorkedWith project="neonlaksa" />
  </Layout>
)

export default NeonLaksa
